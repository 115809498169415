import { T } from '@deity/falcon-i18n';
import React from 'react';
import { Toggle } from 'react-powerplug';
import { SlideDown } from 'react-slidedown';
import { Checkbox } from '../../ui/Checkbox/Checkbox';
import { OptionsList } from '../../ui/OptionsList/OptionsList';
import { CategoryFilterTracking } from '../Tracking/GoogleTagManager';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import styles from './MultipleFilter.module.scss';

const MAX_VISIBLE_ITEMS = 8;

export const MultipleFilter = withStoreConfiguration(
  ({
    storeConfiguration,
    field,
    options,
    selected = [],
    onChange,
    showCount,
    allowShowMore,
    noMargin,
    defaultListVariant
  }) => {
    const handleOnChange = (option, isSelected) => {
      if (isSelected) {
        CategoryFilterTracking({ storeConfiguration, filterName: field, filterElement: option.title });
        onChange([...selected, option.value]);
      } else {
        onChange(selected.filter(value => value !== option.value));
      }
    };

    let visibleOptions = null;
    let hiddenOptions = null;

    const haveElementsToHide = allowShowMore && options.length > MAX_VISIBLE_ITEMS;
    const listOptions = options.map(item => {
      const checkboxLabel = (
        <span>
          {item.title}
          {showCount ? <span className="normal"> ({item.count})</span> : null}
        </span>
      );

      return (
        <Checkbox
          id={`${field}_item_${item.value}`}
          label={checkboxLabel}
          checked={selected.some(value => value === item.value)}
          onChange={e => handleOnChange(item, e.target.checked)}
          value={item.value}
        />
      );
    });

    if (options.length > MAX_VISIBLE_ITEMS && allowShowMore) {
      visibleOptions = listOptions.splice(0, MAX_VISIBLE_ITEMS);
      hiddenOptions = listOptions;
    }

    return (
      <div
        className={[styles.MultipleFilter, noMargin || haveElementsToHide ? styles.MultipleFilterNoMargin : null].join(
          ' '
        )}
      >
        <Toggle initial={false}>
          {({ on, toggle }) => (
            <React.Fragment>
              {haveElementsToHide ? (
                <React.Fragment>
                  <OptionsList variant={defaultListVariant ? 'primary' : 'secondary'} options={visibleOptions} />
                  <SlideDown>
                    <span />
                    {on ? (
                      <OptionsList variant={defaultListVariant ? 'primary' : 'secondary'} options={hiddenOptions} />
                    ) : null}
                  </SlideDown>
                </React.Fragment>
              ) : (
                <OptionsList variant={defaultListVariant ? 'primary' : 'secondary'} options={listOptions} />
              )}

              {haveElementsToHide ? (
                <div className={styles.MultipleFilterShowMoreButton} onClick={toggle} aria-hidden>
                  <span className={on ? 'before-icon-minus' : 'before-icon-plus'}>
                    {on ? <T id="showLess" /> : <T id="showMore" />}
                  </span>
                </div>
              ) : null}
            </React.Fragment>
          )}
        </Toggle>
      </div>
    );
  }
);

MultipleFilter.propsDefault = {
  allowShowMore: false
};
