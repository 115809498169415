import { useEffect, useState } from 'react';

export const useStateWithSsr = (initialState, ssrState) => {
  if (initialState === ssrState) {
    return useState(initialState);
  }

  const [stateVar, setStateVar] = useState(ssrState);
  useEffect(() => {
    setStateVar(initialState);
  }, []);
  return [stateVar, setStateVar];
};
