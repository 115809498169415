import React from 'react';
import InputRange from 'react-input-range';
import { Value } from 'react-powerplug';
import { CategoryFilterTracking } from '../Tracking/GoogleTagManager';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import styles from './RangeSliderFilter.module.scss';

const rearrangeValue = (value, range) => Math.min(Math.max(value, range.min), range.max);

export const RangeSliderFilter = withStoreConfiguration(({ storeConfiguration, title, onChange, range }) => {
  const initValue = { min: range.currentMin, max: range.currentMax, changed: false };
  return (
    <Value initial={initValue}>
      {({ value, set }) => {
        value.min = rearrangeValue(value.changed ? value.min : range.currentMin, range);
        value.max = rearrangeValue(value.changed ? value.max : range.currentMax, range);
        value.changed = false;
        return (
          <div className={styles.RangeSliderFilter}>
            <div className={styles.RangeSliderFilterTitle}>{title}</div>
            <InputRange
              minValue={range.min}
              maxValue={range.max}
              value={value}
              onChange={val => set({ ...val, changed: true })}
              onChangeComplete={val => {
                onChange(val);
                CategoryFilterTracking({
                  storeConfiguration,
                  filterName: 'price min',
                  filterElement: val.min
                });
                CategoryFilterTracking({
                  storeConfiguration,
                  filterName: 'price max',
                  filterElement: val.max
                });
              }}
            />
          </div>
        );
      }}
    </Value>
  );
});
