import { useApolloClient } from '@apollo/react-hooks';

export const useStatelessQuery = () => {
  const apolloClient = useApolloClient();
  return (query, variables, options = {}) => {
    options.query = query;
    options.variables = variables;
    return apolloClient.query(options);
  };
};

export const useStatelessMutate = () => {
  const apolloClient = useApolloClient();
  return (mutation, variables, options = {}) => {
    options.mutation = mutation;
    options.variables = variables;
    return apolloClient.mutate(options);
  };
};
