import { T } from '@deity/falcon-i18n';
import React, { useEffect } from 'react';
import { Toggle } from 'react-powerplug';
import { SlideDown } from 'react-slidedown';
import { Checkbox } from '../../ui/Checkbox/Checkbox';
import { OptionsList } from '../../ui/OptionsList/OptionsList';
import { SelectableButton } from '../../ui/SelectableButton/SelectableButton';
import { CategoryFilterTracking } from '../Tracking/GoogleTagManager';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import styles from './CategoryFilter.module.scss';

const MAX_VISIBLE_ITEMS = 6;

export const CategoryFilter = withStoreConfiguration(({ storeConfiguration, options, selected = [], onChange }) => {
  useEffect(() => {
    options.map(item => {
      function setMaxLabelWidth() {
        const targetLabel = document.getElementById(`label${item.value}`);
        if (targetLabel.offsetWidth > 122 && targetLabel.offsetHeight > 40) {
          targetLabel.classList.add('Active');
        }
      }

      return setMaxLabelWidth();
    });
  }, []);

  const handleOnChange = (option, isSelected) => {
    if (isSelected) {
      onChange(selected.filter(value => value !== option.value));
    } else {
      CategoryFilterTracking({ storeConfiguration, filterName: 'Category', filterElement: option.title });
      onChange([...selected, option.value]);
    }
  };

  let visibleOptions = null;
  let hiddenOptions = null;
  const haveElementsToHide = options.length > MAX_VISIBLE_ITEMS;

  const listOptionsCheckboxes = options.map(item => {
    const isSelected = selected.some(value => value === item.value);

    const checkboxLabel = (
      <span id={`label${item.value}`}>
        {item.title}
        <span className="normal"> ({item.count})</span>
      </span>
    );

    return (
      <Checkbox
        id={`item${item.value}`}
        label={checkboxLabel}
        checked={isSelected}
        onChange={() => handleOnChange(item, isSelected)}
        value={item.value}
      />
    );
  });

  const listOptionsButtons = options.map(item => {
    const isSelected = selected.some(value => value === item.value);

    return (
      <SelectableButton
        className={styles.CategoryFilterButton}
        label={item.title}
        isSelected={isSelected}
        key={item.value}
        handleOnClick={() => handleOnChange(item, isSelected)}
      />
    );
  });

  if (options.length > MAX_VISIBLE_ITEMS) {
    visibleOptions = listOptionsButtons.splice(0, MAX_VISIBLE_ITEMS);
    hiddenOptions = listOptionsButtons;
  }

  return (
    <div className={styles.CategoryFilter}>
      <div className={styles.CategoryFilterCheckboxes}>
        <OptionsList variant="secondary" options={listOptionsCheckboxes} />
      </div>

      <Toggle initial={false}>
        {({ on, toggle }) => (
          <React.Fragment>
            <div className={styles.CategoryFilterButtons}>
              {haveElementsToHide ? (
                <React.Fragment>
                  {visibleOptions}
                  <SlideDown>{on ? hiddenOptions : null}</SlideDown>
                </React.Fragment>
              ) : (
                listOptionsButtons
              )}
            </div>
            {haveElementsToHide ? (
              <div
                className={[styles.CategoryFilterShowMoreButton, 'ShowTablet', on ? styles.Active : null].join(' ')}
                onClick={toggle}
                aria-hidden
              >
                <span className="Link LinkPurple LinkNoUnderline LinkWithIcon LinkWithMediumWeight after-icon-chevron-down">
                  {on ? <T id="filters.showLessCategories" /> : <T id="filters.showMoreCategories" />}
                </span>
              </div>
            ) : null}
          </React.Fragment>
        )}
      </Toggle>
    </div>
  );
});
