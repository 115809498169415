import { FilterDetails } from '@deity/falcon-ui-kit';
import React from 'react';
import { Toggle } from 'react-powerplug';
import styles from './FilterTile.module.scss';

export const FilterTile = ({ title, initiallyOpen, children }) => (
  <Toggle initial={initiallyOpen}>
    {({ on, toggle }) => (
      <div className={[styles.FilterTile, on ? styles.Open : null].join(' ')}>
        <div className={[styles.FilterTileTitle, 'after-icon-chevron-down'].join(' ')} onClick={toggle} aria-hidden>
          {title}
        </div>
        <div className={styles.FilterTileContent}>{children}</div>
      </div>
    )}
  </Toggle>
);

FilterDetails.defaultProps = {
  initiallyOpen: false
};
