import { T } from '@deity/falcon-i18n';
import React from 'react';
import { Button } from '../Button/Button';
import styles from './ShowMore.module.scss';

export const ShowMore = ({ onClick, loading }) => (
  <div className={styles.ShowMore}>
    <Button types="lessmore" loading={loading}>
      {/* eslint-disable-next-line no-script-url */}
      <a href="javascript:void(0)" className="before-icon-plus" onClick={onClick}>
        <T id="showMore" />
      </a>
    </Button>
  </div>
);
