import { I18n, T } from '@deity/falcon-i18n';
import React from 'react';
import { Checkbox } from '../../ui/Checkbox/Checkbox';
import { Dropdown } from '../../ui/Dropdown/Dropdown';
import { OptionsList } from '../../ui/OptionsList/OptionsList';
import { SortProductListTracking } from '../Tracking/GoogleTagManager';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import styles from './SortOrder.module.scss';

const getSortOptionValue = item =>
  (item && item.value && `${item.value.field}:${item.value.direction || 'asc'}`) || 'undefined';
const getSortOptionByValue = (value, options) => options.find(item => getSortOptionValue(item) === value);

const getLabelWithDirectionForItem = (t, item) =>
  t('productList.sort.valueWithDirection', {
    val: item.name,
    dir: t(`productList.sort.direction.${item.value.direction}`)
  });

const getLabelForItem = (t, item, items) => {
  return items.find(it => item !== it && item.value.field === it.value.field)
    ? getLabelWithDirectionForItem(t, item)
    : item.name;
};

export const SortOrder = withStoreConfiguration(
  ({ storeConfiguration, items, value, onChange, optionList, availableSortBy, defaultSortBy }) => (
    <I18n>
      {t => {
        if (availableSortBy) {
          items = items.filter(item => availableSortBy.find(sortby => sortby === getSortOptionValue(item)));
        }

        const options = items.map(item => ({
          label: getLabelForItem(t, item, items),
          value: getSortOptionValue(item),
          item
        }));

        const defaultSort = (defaultSortBy && getSortOptionByValue(defaultSortBy, items)) || items[0];

        if (optionList) {
          const checkboxOptions = options.map((option, index) => (
            <Checkbox
              value={option.value}
              label={option.label}
              name="sorting"
              key={option.value}
              id={`item${index}`}
              renderAsRadio
              checked={getSortOptionValue(value || defaultSort) === option.value}
              readOnly
              onAction={() => onChange(option.item)}
            />
          ));

          return <OptionsList options={checkboxOptions} />;
        }

        return (
          <div className={styles.SortOrder}>
            <div className={styles.SortOrderTitle}>
              <T id="productList.sort.title" />
            </div>
            <div className={styles.SortOrderDropdown}>
              <Dropdown
                options={options}
                onChange={selectedItem => {
                  onChange(getSortOptionByValue(selectedItem, items));
                  SortProductListTracking({ storeConfiguration, selectedSorting: selectedItem });
                }}
                preSelected={getSortOptionValue(value || defaultSort)}
                size="tiny"
                type="tertiary"
                boldLabel
              />
            </div>
          </div>
        );
      }}
    </I18n>
  )
);
