import React from 'react';
import PropTypes from 'prop-types';
import styles from './SelectableButton.module.scss';

export const SelectableButton = ({ className, label, isSelected, fullWidth, resetFloating, handleOnClick }) => {
  const additionalClasses = [];

  if (className) {
    additionalClasses.push(className);
  }

  if (resetFloating) {
    additionalClasses.push(styles.noFloating);
  }

  if (fullWidth) {
    additionalClasses.push(styles.fullWidth);
  }

  if (isSelected) {
    additionalClasses.push(styles.Selected);
  }

  return (
    <div
      className={[
        styles.SelectableButton,
        'SelectableButton',
        ...additionalClasses,
        isSelected ? 'after-icon-check' : null
      ].join(' ')}
      onClick={() => handleOnClick()}
      aria-hidden
    >
      {label}
    </div>
  );
};

SelectableButton.propTypes = {
  /** Set true if button is selected */
  isSelected: PropTypes.bool,
  /** Label displayed inside button */
  label: PropTypes.string,
  /** Set button width to 100% */
  fullWidth: PropTypes.bool,
  /** Remove floating styles */
  resetFloating: PropTypes.bool
};
