import { getSelectedFilterOptionsFor, SearchConsumer } from '@deity/falcon-front-kit';
import { T } from '@deity/falcon-i18n';
import React from 'react';
import styles from './FiltersSummary.module.scss';

const getSelectedRangeFilterOptionFor = (data, field) => {
  const filter = data.find(x => x.field === field);

  if (filter === undefined || filter.type !== 'range') {
    return [];
  }
  const {
    options,
    value: [selectedMin, selectedMax]
  } = filter;

  return options.filter(({ range: [rangeMin, rangeMax] }) => {
    return Math.round(selectedMin) > Math.round(rangeMin) || Math.round(selectedMax) < Math.round(rangeMax);
  });
};

const getOptionsFor = (data, field, type) => {
  switch (type) {
    case 'range':
      return getSelectedRangeFilterOptionFor(data, field);
    default:
      return getSelectedFilterOptionsFor(data, field);
  }
};

const getOptionTitle = (filterType, filterTitle, option) => {
  switch (filterType) {
    case 'range':
      return filterTitle;
    case 'single':
      return `${filterTitle}: ${option.title || option.value}`;
    case 'multiple':
    default:
      return option.title || option.value;
  }
};

const removeFilterOption = (filters, field, value, setFilter) => {
  const filter = filters.find(x => x.field === field);
  if (filter) {
    if (filter.operator === 'range') {
      setFilter(field, []);
    } else {
      const newValue = filter.value.filter(val => val !== value);
      setFilter(field, newValue);
    }
  }
};

export const FiltersSummary = ({ data }) => (
  <SearchConsumer>
    {({ state: { filters }, setFilter, removeFilters }) => {
      if (!filters.length) {
        return null;
      }

      return (
        <div className={styles.FiltersSummary}>
          <div className={styles.FiltersSummaryList}>
            {data.map(({ field, type, title }) => {
              const selectedFilterOptions = getOptionsFor(data, field, type);

              if (selectedFilterOptions.length === 0) {
                return null;
              }

              return (
                <React.Fragment key={field}>
                  <span
                    className={[styles.FiltersSummaryCloseLink, 'Link', 'LinkPurple', 'LinkLarge'].join(' ')}
                    onClick={() => removeFilters()}
                    aria-hidden
                  >
                    <T id="filters.removeAll" />
                  </span>
                  {selectedFilterOptions.map(item => (
                    <div
                      className={[styles.FiltersSummaryListItem, 'after-icon-x'].join(' ')}
                      key={item.value}
                      onClick={() => removeFilterOption(filters, field, item.value, setFilter)}
                      aria-hidden
                    >
                      {getOptionTitle(type, title, item)}
                    </div>
                  ))}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      );
    }}
  </SearchConsumer>
);
