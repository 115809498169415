import { SearchConsumer } from '@deity/falcon-front-kit';
import { I18n, T } from '@deity/falcon-i18n';
import { FilterOperator } from '@deity/falcon-shop-data';
import React from 'react';
import { Multiselect } from '../../ui/Multiselect/Multiselect';
import { CategoryFilter } from './CategoryFilter';
import styles from './Filters.module.scss';
import { FilterTile } from './FilterTile';
import { MultipleFilter } from './MultipleFilter';
import { RangeSliderFilter } from './RangeSliderFilter';

const getCategoryFilter = (categoryFilter, setFilter) => {
  return categoryFilter !== null ? (
    <div className={styles.FiltersGroup}>
      <div className={[styles.FiltersGroupTitle, 'HideTablet'].join(' ')}>
        <T id="filters.categories" />
      </div>
      <div className={[styles.FiltersGroupOptions, styles.CategoryGroupOptions].join(' ')}>
        <CategoryFilter
          options={categoryFilter.options}
          selected={categoryFilter.value}
          onChange={x => setFilter(categoryFilter.field, x, FilterOperator.equals)}
        />
      </div>
    </div>
  ) : null;
};

const getRestFilter = (restFilters, setFilter, defaultListVariant) => {
  return restFilters.map(({ field, title, options, value }) => {
    return (
      <div className={[styles.FiltersGroupOptions, styles.Secondary].join(' ')} key={field}>
        <FilterTile key={field} title={title} initiallyOpen={value && value.length > 0}>
          {(() => (
            <MultipleFilter
              field={field}
              options={options}
              selected={value}
              allowShowMore
              onChange={x => setFilter(field, x, FilterOperator.equals)}
              defaultListVariant={defaultListVariant}
            />
          ))()}
        </FilterTile>
      </div>
    );
  });
};

const getPriceFilterSlider = (priceFilter, setFilter) => {
  const [min = 0, max = 999] = (priceFilter.options &&
    priceFilter.options[0].range &&
    priceFilter.options[0].range.length > 1 &&
    priceFilter.options[0].range.map(item => item && parseFloat(item))) || [0, 999];
  const [currentMin = 0, currentMax = 999] = (priceFilter.value instanceof Array &&
    priceFilter.value.length > 1 &&
    priceFilter.value.map(item => item && parseFloat(item))) || [0, 999];
  const range = {
    min: Math.floor(min),
    max: Math.ceil(max),
    currentMin: Math.floor(Math.max(currentMin, min)),
    currentMax: Math.ceil(Math.min(currentMax, max))
  };
  if (range.min === range.max) {
    range.max++;
  }
  return (
    <RangeSliderFilter
      title={priceFilter.title}
      onChange={x => setFilter(priceFilter.field, [x.min, x.max], FilterOperator.range)}
      range={range}
    />
  );
};

const getPriceFilter = (priceFilter, setFilter) => {
  return priceFilter != null ? (
    <div className={[styles.FiltersGroupOptions, styles.RangeSlider].join(' ')} key="pricefilter">
      {getPriceFilterSlider(priceFilter, setFilter)}
    </div>
  ) : null;
};

const getPriceFilterWithDropdown = (priceFilter, setFilter) => {
  return priceFilter != null ? (
    <div className={[styles.FiltersGroupOptions, styles.RangeSlider].join(' ')} key="pricefilterDropdown">
      <FilterTile title={priceFilter.title}>{getPriceFilterSlider(priceFilter, setFilter)}</FilterTile>
    </div>
  ) : null;
};

export const Filters = ({ data, ...rest }) => {
  let categoryFilter,
    priceFilter = null;
  const restFilters = [];

  return (
    <SearchConsumer>
      {({ setFilter }) => {
        data.forEach(item => {
          switch (item.field) {
            case 'categories':
              categoryFilter = item;
              break;
            case 'price':
              priceFilter = item;
              break;
            default:
              restFilters.push(item);
          }
        });

        const categoryFilterHtml = categoryFilter ? getCategoryFilter(categoryFilter, setFilter) : null;
        const restFilterPrimaryHtml = restFilters ? getRestFilter(restFilters, setFilter, true) : null;
        const restFilterSecondaryHtml = restFilters ? getRestFilter(restFilters, setFilter, false) : null;
        const priceFilterHtml = priceFilter ? getPriceFilter(priceFilter, setFilter) : null;
        const priceFilterWithDropdownHtml = priceFilter ? getPriceFilterWithDropdown(priceFilter, setFilter) : null;

        return (
          <nav {...rest} className={styles.Filters}>
            {categoryFilterHtml}

            {priceFilter !== null || restFilters.length ? (
              <React.Fragment>
                <div className="HideTablet">
                  <div className={styles.FiltersGroup}>
                    <div className={styles.FiltersGroupTitle}>
                      <T id="filters.filterBy" />
                    </div>
                    {restFilterSecondaryHtml}
                    {priceFilterHtml}
                  </div>
                </div>
                <div className="ShowTablet">
                  <I18n>
                    {t => (
                      <Multiselect
                        removePaddingOnMobile
                        iconVariant="secondary"
                        headers={[t('filters.filterBy')]}
                        contents={[restFilterPrimaryHtml.concat(priceFilterWithDropdownHtml)]}
                        blockContentEvents
                      />
                    )}
                  </I18n>
                </div>
              </React.Fragment>
            ) : null}
          </nav>
        );
      }}
    </SearchConsumer>
  );
};
